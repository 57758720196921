import React, { useState } from 'react'
import next from '../../Images/next.png'
import './Menu.scss';
import AreaData from '../../Components/ServicesPage/AreaData'
import LocationData from '../../Components/LocationAll/LocationData'

export default function Menu({ navigateTo, setShowMenu, WebSitePages }) {

    const [showMakers, setshowMakers] = useState(false);
    return (
        <div className="Menu">
            <div className="MenuBoxShadow" onClick={() => setShowMenu(false)}></div>
            <div className="MenuList">
                <div className="MenuListTop">
                    <div className="MenuMain">
                        <div className="MenuMainBox">
                            <a href='/'>Home</a>
                        </div>
                        <div className="MenuMainBox" onClick={() => {
                            setshowMakers(true)
                        }}>
                            <h1 >Services</h1>
                            <img src={next} alt="" />
                        </div>
                        <div className="MenuMainBox">
                            <a href='/book'>Book An Appointment</a>
                        </div>
                        <div className="MenuMainBox">
                            <a href={`/reviews/${LocationData.find(a=>a.City == ("Long Beach").replace("%20",'')).City.replace(" ",'').toLowerCase()}`}>Reviews</a>
                        </div>
                        <div className="MenuMainBox">
                            <a href='/locations'>Locations</a>
                        </div>
                        <div className="MenuMainBox">
                            <a href='/contact'>Contact Us</a>
                        </div>
                        <div className="MenuMainBox">
                            <a href='/blog'>Blog</a>
                        </div>
                    </div>
                    <div className="MenuFooter">
                        <h5>Copyright © 2024 Long Beach Garage Door. All rights reserved.</h5>
                    </div>

                    {/* showCategories */}
                    {showMakers ?
                        <div className="MenuMakers">
                            <div className="MenuMakersHeader">
                                <img src={next} alt="" onClick={() => {
                                    setshowMakers(false)
                                }} />
                                <h1 onClick={() => {
                                    // navigateTo("")
                                }}>Services</h1>
                            </div>
                            <div className="MenuMakersList">
                                <div className="MenuMakersListBox">
                            <a className="Header1BoxMenuBox" href={`/services`}>ALL SERVICES</a>
                                    {AreaData.map((A, index) => 
                                        <a className="Header1BoxMenuBox" href={`${A.Path}`}>{A.Title}</a>
                                    )}
                                </div>

                            </div>

                        </div>
                        : null}
                </div>
                <div className="MenuListFooter">
                    <p>
                        Monday - Sunday
                        <br />
                        8:00 a.m. - 8:00 p.m. EST
                    </p>
                    <a href="tel:562-553-8276">CALL NOW 562-553-8276</a>
                    <a href="/book">BOOK AN APPOINTMENT</a>
                </div>

            </div>
        </div>
    )
}
